<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-card>
      <v-row justify="end">
        <v-col col="9" class="pt-0 pb-0" align="start" align-self="center">
          <v-btn-toggle dense color="primary" group>
            <v-btn
              value="CARGA MANUAL"
              :disabled="mostrarManual"
              @click="changeTab(1)"
              >Carga manual</v-btn
            >
            <v-btn
              value="IMPORTAR"
              :disabled="mostrarImportar"
              @click="changeTab(2)"
              >Importar</v-btn
            >
          </v-btn-toggle>
        </v-col>
      </v-row>
      <div v-if="mostrarManual == true">
        <v-form v-model="isFormValid" ref="filters-form" id="filters-form">
          <v-row>
            <!-- DIVISOR -->
            <v-col cols="12">
              <v-card-title>Datos personales</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="4" sm="6" md="3" class="py-0">
                    <v-menu
                      ref="menu-fecha-vigencia"
                      v-model="menuFechaAlta"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaAltaSelected"
                          label="Fecha de alta"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          @change="
                            fechaAltaSelected == null
                              ? ($refs['convenios-autocomplete'].reset(),
                                $refs['planes-autocomplete'].reset())
                              : null
                          "
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          @blur="fechaAlta = parseDateToIso(fechaAltaSelected)"
                          v-on="on"
                          :rules="rules.required.concat(rules.validDate)"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaAlta"
                        no-title
                        @change="fechaAltaSelected = formatDate(fechaAlta)"
                        @input="menuFechaAlta = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="2" class="py-0">
                    <v-autocomplete
                      v-model="tipoDocSelected"
                      :items="tiposDoc"
                      label="Tipo de documento"
                      :rules="rules.required"
                      item-text="value"
                      item-value="id"
                      return-object
                      outlined
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Número de documento -->
                  <v-col cols="12" sm="6" md="2" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      label="N° de documento"
                      v-mask="['#.###.###', '##.###.###']"
                      v-model="docAfiliado"
                      autocomplete="off"
                      :rules="
                        rules.required.concat([rules.minLength(docAfiliado, 7)])
                      "
                    ></v-text-field>
                  </v-col>
                  <!-- afiliado -->
                  <v-col cols="6" sm="6" md="4" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      :rules="rules.required"
                      label="N° de afiliado"
                      v-model="afiliado"
                      v-mask="'###############'"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Convenios -->
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-autocomplete
                      ref="convenios-autocomplete"
                      class="p-0"
                      :disabled="fechaAltaSelected == null"
                      v-model="conveniosSelected"
                      :items="itemsConvenio"
                      label="Convenio"
                      return-object
                      item-text="value"
                      item-value="id"
                      :rules="rules.required"
                      @change="
                        setPlanesByConvenios(),
                          conveniosSelected == null || conveniosSelected == []
                            ? $refs['planes-autocomplete'].reset()
                            : null
                      "
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Plan -->
                  <v-col cols="4" md="4" sm="4" class="pb-0 py-0">
                    <v-autocomplete
                      ref="planes-autocomplete"
                      v-model="planesSelected"
                      :loading="loadingPlanesByConvenio"
                      :items="planes"
                      label="Plan"
                      :rules="rules.required"
                      return-object
                      :disabled="
                        conveniosSelected == null ||
                          conveniosSelected == [] ||
                          conveniosSelected.length == 0
                      "
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Apellido/s -->
                  <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      label="Apellido/s"
                      v-model.trim="apellido"
                      autocomplete="off"
                      class="theme--light.v-input input"
                      :rules="
                        rules.required.concat([
                          rules.requiredTrim(apellido),
                          rules.maxLength(apellido, 45)
                        ])
                      "
                    ></v-text-field>
                  </v-col>
                  <!-- Nombre/s -->
                  <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      label="Nombre/s"
                      v-model="nombre"
                      autocomplete="off"
                      :rules="[rules.maxLength(nombre, 45)]"
                    ></v-text-field>
                  </v-col>
                  <!-- Sexo -->
                  <v-col cols="12" sm="6" md="2" class="py-0">
                    <v-autocomplete
                      v-model="generoSelected"
                      :items="generos"
                      label="Sexo"
                      :rules="rules.required"
                      item-text="value"
                      item-value="value"
                      outlined
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="4" class="py-0">
                    <v-autocomplete
                      v-model="localidadSelected"
                      ref="localidad"
                      :items="localidades"
                      item-text="value"
                      item-value="id"
                      return-object
                      label="Localidad"
                      outlined
                      clearable
                      dense
                      :rules="rules.required"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-5"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Borrar datos ingresados</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    elevation="2"
                    :disabled="!isFormValid"
                    :loading="isLoadingBtn"
                    @click="saveAfi()"
                    class="to-right"
                  >
                    Guardar
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <div v-if="mostrarImportar">
        <v-container>
          <v-form
            v-model="isFormValidImportar"
            ref="form-import"
            form="form-import"
            id="form-import"
          >
            <!-- Proceso de importación -->
            <v-row cols="10" class="py-0 mt-6">
              <v-col cols="7" class="py-0">
                <v-file-input
                  ref="file"
                  v-model="excelFile"
                  :disabled="loadingFile"
                  :loading="loadingFile"
                  id="file"
                  type="file"
                  dense
                  label="Excel"
                  placeholder="Seleccionar el archivo Excel"
                  accept=".xls,.xlsx,.csv"
                  outlined
                  :show-size="1000"
                  @change="readExcel"
                  :rules="rules.required"
                  prepend-icon="mdi-file-find-outline"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip
                      :color="!loadingFile ? 'primary' : 'grey lighten-1'"
                      dark
                      label
                      small
                    >
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" class="pt-0 pb-0">
                <v-btn
                  color="primary"
                  class="no-upper-case to-right"
                  outlined
                  @click="openModalSeeFormatoArchivo()"
                >
                  Ver formato
                </v-btn></v-col
              >
            </v-row>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              :disabled="!isFormValidImportar || loadingFile"
              form="form-import"
              :loading="loadingFile"
              color="primary"
              @click.stop.prevent="saveImportar()"
            >
              Importar
            </v-btn>
          </v-card-actions>
        </v-container>
        <FormatoArchivoDialog
          :formatoTitle="formatoTitle"
          :headers="headersFormato"
          :datosFormatoArchivo="datosFormatoArchivo"
          :dataToExport="dataToExport"
          :openFormatoArchivoDialog.sync="showSeeFormatModal"
        />
      </div>
    </v-card>
    <v-row class="py-0 mt-2">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="itemsTabla"
          :items-per-page="10"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.tipoYnumDoc`]="{ item }"
            ><span
              >{{ item.tipoDocumento }} - {{ item.documento }}
            </span></template
          >
          <template v-slot:[`item.apeYNom`]="{ item }"
            ><span>{{ item.apellido }} {{ item.nombre }} </span></template
          >
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            :disabled="this.itemsTabla.length == 0"
            @click="exportExcelRegistros()"
            class="to-right fontsize py-4"
          >
            Exportar lista completa
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>

    <ConfirmDialog
      :text="textConfirmDialog"
      :openConfirm.sync="openConfirmDialog"
      @onConfirm="exportErrores()"
    />
  </v-container>
</template>
<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import moment from "moment";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import FormatoArchivoDialog from "@/components/shared/FormatoArchivoDialog.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";
import { mask } from "vue-the-mask";
export default {
  name: "AfiliadosAConveniosSPadron",
  directives: { mask },
  components: {
    PageHeader,
    GoBackBtn,
    FormatoArchivoDialog,
    ConfirmDialog
  },
  created() {
    this.setSelects();
  },
  data() {
    return {
      routeToGo: "GestionAfiliados",
      openConfirmDialog: false,
      importeTablas: [],
      observaciones: "",
      datosFormatoArchivo: [],
      showSeeFormatModal: false,
      modalExportarExcel: false,
      rules: rules,
      itemsTabla: [],
      registrosError: [],
      isFormValidImportar: false,
      excelData: [],
      excelFile: null,
      loadingFile: false,
      archivoEnProceso: false,
      creaUnidades: false,
      dataToExport: {
        formatoName: "Formato importación afiliados a convenios sin padrón",
        data: null
      },
      formatoTitle: "Formato de archivo",
      headersFormato: [
        {
          text: "Nombre campo",
          align: "start",
          sortable: false,
          value: "nombreCampo"
        },
        {
          text: "Orden campo",
          align: "start",
          sortable: false,
          value: "ordenCampo"
        },
        {
          text: "Longitud máxima",
          align: "start",
          sortable: false,
          value: "longMax"
        },
        {
          text: "Formato",
          value: "formato",
          align: "start",
          sortable: false
        },
        {
          text: "Requerido",
          value: "requerido",
          align: "center",
          sortable: false
        },
        {
          text: "Descripción",
          value: "descripcion",
          align: "start",
          sortable: false
        }
      ],
      headers: [
        {
          text: "Fecha de alta",
          value: "fechaAlta",
          sortable: false
        },
        {
          text: "N° de afiliado",
          value: "numeroAfiliado",
          sortable: false
        },
        {
          text: "Tipo y N° de documento",
          value: "tipoYnumDoc",
          sortable: false
        },
        {
          text: "Apellido/s y nombre/s",
          value: "apeYNom",
          sortable: false
        },
        {
          text: "Sexo",
          value: "sexo",
          align: "center",
          sortable: false
        },
        {
          text: "Convenio",
          value: "convenio",
          sortable: false
        },
        {
          text: "Plan",
          value: "plan",
          sortable: false
        },
        {
          text: "Localidad",
          value: "localidad",
          sortable: false
        }
      ],
      mostrarManual: true,
      mostrarImportar: false,
      tiposDoc: [],
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      tipoDocSelected: null,
      docAfiliado: null,
      afiliado: null,
      itemsTabla: [],
      conveniosSelected: [],
      planesSelected: [],
      itemsConvenio: [],
      localidades: [],
      localidadSelected: null,
      loadingPlanesByConvenio: false,
      fechaAltaSelected: null,
      menuFechaAlta: null,
      fechaAlta: null,
      textConfirmDialog: "",
      title: "Afiliados a convenios sin padrón",
      isFormValid: false,
      calendarIcon: enums.icons.CALENDAR,
      rules: rules,
      planesSelected: null,
      planes: [],
      numeroAfiliado: null,
      apellido: null,
      nombre: null,
      generoSelected: null,
      generos: [{ value: "M" }, { value: "F" }],
      localidades: [],
      isLoadingBtn: false,
      afiErrores: [],
      afiSinErrores: [],
      excelDataTranslated: []
    };
  },
  methods: {
    ...mapActions({
      getParametrosGralesBenef: "afiliaciones/getParametrosGralesBenef",
      fetchTiposProcesosFormatosById:
        "prestadores/fetchTiposProcesosFormatosById",
      getConveniosSPadron: "afiliaciones/getConveniosSPadron",
      getLocalidades: "afiliaciones/getLocalidades",
      getPlanesByConvenioAndVigencia:
        "prestadores/getPlanesByConvenioAndVigencia",
      getTiposDoc: "afiliaciones/getTiposDoc",
      saveAfiliadoConvenioSPadron: "afiliaciones/saveAfiliadoConvenioSPadron",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const convenios = await this.getConveniosSPadron();
      this.itemsConvenio = convenios.data;
      const locResponse = await this.getLocalidades();
      this.localidades = locResponse;
      const response = await this.getParametrosGralesBenef();
      this.parametrosGrales = response;
      const tiposDoc = await this.getTiposDoc();
      this.tiposDoc = tiposDoc;
      this.tipoDocSelected = this.tiposDoc.find(
        x => x.id == this.parametrosGrales.tdPredet
      );
    },
    changeTab(num) {
      switch (num) {
        case 1:
          this.mostrarManual = true;
          this.mostrarImportar = false;
          break;
        case 2:
          this.mostrarManual = false;
          this.mostrarImportar = true;
          break;
      }
    },
    async resetForm() {
      this.$refs["filters-form"].reset();
      const tiposDoc = await this.getTiposDoc();
      this.tiposDoc = tiposDoc;
      this.tipoDocSelected = this.tiposDoc.find(
        x => x.id == this.parametrosGrales.tdPredet
      );
    },
    async setPlanesByConvenios() {
      this.loadingPlanesByConvenio = true;
      if (!this.conveniosSelected) {
        this.planesSelected = [];
      } else {
        const idConvenios = this.conveniosSelected.id;
        const planes = await this.getPlanesByConvenioAndVigencia({
          vigencia: this.fechaAltaSelected,
          conveniosIds: [idConvenios]
        });
        this.planes = planes;
      }
      this.loadingPlanesByConvenio = false;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    convertirFecha(fecha) {
      var fechaConvertida = moment(fecha, "DD-MM-YYYY").format("DD/MM/YYYY");
      return fechaConvertida;
    },
    async saveAfi() {
      const datosIngresados = {
        apellido: this.apellido,
        nombre: this.nombre,
        tipoDocumento: this.tipoDocSelected.value,
        documento: this.docAfiliado.replaceAll(".", ""),
        numeroAfiliado: this.afiliado,
        convenio: this.conveniosSelected.value,
        plan: this.planesSelected.value,
        sexo: this.generoSelected,
        fechaAlta: this.fechaAltaSelected,
        localidad: this.localidadSelected.value
      };
      const ingresados = {
        apellido: this.apellido,
        nombre: this.nombre,
        tipoDocumento: this.tipoDocSelected.value,
        documento: this.docAfiliado.replaceAll(".", ""),
        numeroAfiliado: this.afiliado,
        convenio: this.conveniosSelected.value,
        plan: this.planesSelected.value,
        sexo: this.generoSelected,
        fechaAlta: this.fechaAltaSelected,
        localidad: this.localidadSelected.value
      };
      this.isLoadingBtn = true;
      const data = {
        afiliados: [datosIngresados]
      };
      try {
        const resp = await this.saveAfiliadoConvenioSPadron(data);
        this.afiErrores = resp.afiliadosConErrores;
        if (this.afiErrores.length > 0) {
          this.setAlert({
            type: "error",
            message: `Existen registros con error. ${this.afiErrores[0].obvservaciones}`
          });
          this.isLoadingBtn = false;
        } else {
          this.resetForm();
          this.itemsTabla.push(ingresados);
          this.setAlert({
            type: "success",
            message: "Guardado con éxito"
          });
          this.isLoadingBtn = false;
        }
      } catch {
        this.isLoadingBtn = false;
      }
    },
    exportExcelRegistros() {
      let result = [];
      this.itemsTabla?.forEach(x =>
        result.push({
          ["Fecha de alta"]: this.convertirFecha(x.fechaAlta),
          ["NumeroAfiliado"]: x.numeroAfiliado,
          ["TipoDocumento"]: x.tipoDocumento,
          ["documento"]: x.documento,
          ["Apellido"]: x.apellido,
          ["Nombre"]: x.nombre,
          ["Sexo"]: x.sexo,
          ["Convenio"]: x.convenio,
          ["Plan"]: x.plan,
          ["Localidad"]: x.localidad
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Listado de registros");
    },
    exportErrores() {
      let result = [];
      this.afiErrores?.forEach(x =>
        result.push({
          ["Fecha de alta"]: x.fechaAlta,
          ["NumeroAfiliado"]: x.numeroAfiliado,
          ["TipoDocumento"]: x.tipoDocumento,
          ["documento"]: x.documento,
          ["Apellido"]: x.apellido,
          ["Nombre"]: x.nombre,
          ["Sexo"]: x.sexo,
          ["Convenio"]: x.convenio,
          ["Plan"]: x.plan,
          ["Localidad"]: x.localidad,
          ["Observaciones"]: x.obvservaciones
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Listado de errores");
    },
    convertirDate(cadenaFecha) {
      const fecha = new Date(cadenaFecha);
      const dia = fecha
        .getDate()
        .toString()
        .padStart(2, "0");
      const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
      const año = fecha.getFullYear().toString();
      return `${dia}/${mes}/${año}`;
    },

    //modal importar
    async saveImportar() {
      this.loadingFile = true;
      const data = {
        afiliados: this.excelDataTranslated
      };
      try {
        const resp = await this.saveAfiliadoConvenioSPadron(data);
        this.afiErrores = resp.afiliadosConErrores;
        this.afiSinErrores = resp.afiliadosSinErrores
        if(this.afiSinErrores.length > 0){
        this.setAlert({
          type: "success",
          message: "Importado con éxito"
        });
        this.loadingFile = false;
        this.$refs["file"].reset();
        resp.afiliadosSinErrores.forEach(afis => {
          this.itemsTabla.push({
            fechaAlta: this.convertirFecha(afis["fechaAlta"]),
            numeroAfiliado: afis["numeroAfiliado"],
            tipoDocumento: afis["tipoDocumento"],
            documento: afis["documento"],
            apellido: afis["apellido"],
            nombre: afis["nombre"],
            sexo: afis["sexo"],
            convenio: afis["convenio"],
            plan: afis["plan"],
            localidad: afis["localidad"]
          });
        });
        }
        if (resp.afiliadosConErrores.length > 0) {
          this.openConfirmDialog = true;
          this.textConfirmDialog =
            "Existen registros con errores, ¿desea exportar el archivo?";
          this.$refs["file"].reset();
          this.loadingFile = false;
        }
      } catch (error) {
        this.setAlert({
          type: "error",
          message: "Error al importar"
        });
        this.loadingFile = false;
        this.isLoadingBtn = false;
      }
    },
    getArchivo(rowsToIgnore) {
      // si el loadingFile es false, significa que es la primera vez que llama al metodo
      if (this.archivoEnProceso == false) {
        this.loadingFile = true;
        this.archivoEnProceso = true;
        this.$refs["file"].validate(true);
        if (this.$refs.file.validate()) {
          this.excelData = helpersExcel.excelImport(
            this.excelFile,
            rowsToIgnore
          );
        }
      }
    },
    async readExcel() {
      if (this.excelFile) {
        try {
          const expectedHeaders = [
            "FechaAlta",
            "NumeroAfiliado",
            "TipoDocumento",
            "Documento",
            "Apellido",
            "Nombre",
            "Sexo",
            "Convenio",
            "Plan",
            "Localidad"
          ];
          const validHeaders = await helpersExcel.validarCabecerasExcel(
            this.excelFile,
            expectedHeaders
          );
          if (!validHeaders) {
            this.loadingFile = false;
            this.$refs["file"].reset();
            return;
          }
          this.loadingFile = true;
          this.getArchivo(0);
          const archivoValido = this.verifArchivo();
          if (archivoValido) {
            this.fileName = this.excelFile.name;
            this.excelDataTranslated = [];
            this.excelData[0]?.filas.forEach(x => {
              this.excelDataTranslated.push({
                fechaAlta: this.convertirFecha(x["FechaAlta"]),
                numeroAfiliado: x["NumeroAfiliado"],
                tipoDocumento: x["TipoDocumento"],
                documento: x["Documento"],
                apellido: x["Apellido"],
                nombre: x["Nombre"],
                sexo: x["Sexo"],
                convenio: x["Convenio"],
                plan: x["Plan"],
                localidad: x["Localidad"]
              });
            });
            this.loadingFile = false;
          } else {
            setTimeout(this.readExcel, 2000);
          }
        } catch (error) {
          // contempla el error de formato dentro del importExportExcel.js
          this.loadingFile = false;
          this.setAlert({ type: "warning", message: error });
          this.$refs["file"].reset();
        }
      }
    },
    closeModal() {
      this.$emit("closeModalImportar");
    },
    verifArchivo() {
      if (this.excelData[0]?.filas != undefined) {
        this.archivoEnProceso = false;
        return true;
      }
      return false;
    },
    async openModalSeeFormatoArchivo() {
      const formatoProceso = await this.fetchTiposProcesosFormatosById(36);
      this.datosFormatoArchivo = formatoProceso;
      const nuevoObjeto = formatoProceso.reduce((obj, campo) => {
        obj[campo.nombreCampo] = campo.datoEjemplo;
        return obj;
      }, {});
      nuevoObjeto.Descripcion = "";
      const array1 = [
        "12/10/2021",
        "205468",
        "Dni",
        36235265,
        "Perez",
        "Juan",
        "M",
        "Medicus",
        "Nuevo plan",
        "Adrogue"
      ];
      const array2 = [
        "22/05/2022",
        "125896",
        "Dni",
        31220387,
        "Lopez",
        "Rita",
        "F",
        "Macena",
        "Demo",
        "Achiras"
      ];
      const objeto1 = array1.reduce((obj, value, index) => {
        const campo = Object.keys(nuevoObjeto)[index];
        obj[campo] = value;
        return obj;
      }, {});
      const objeto2 = array2.reduce((obj, value, index) => {
        const campo = Object.keys(nuevoObjeto)[index];
        obj[campo] = value;
        return obj;
      }, {});
      const resultado = [objeto1, objeto2];
      this.dataToExport.data = resultado;
      this.showSeeFormatModal = true;
    }
  }
};
</script>
<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}
::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
